import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Dictionary } from '../../../consts/dictionary';
import { Colors } from '../../../consts/colors.const';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import { TextFieldWithRegexValidation } from '../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';

export const TeamName = 'name';
export const GitLabGroupId = 'id';

export const TeamsEditor = ({ onSubmit, team }) => {

    const [state, setState] = useState({
        disableSubmit: !team?.name || !team?.id,
        [TeamName]: team?.name || '',
        [GitLabGroupId]: team?.id || '',
    });

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[TeamName]
                || !newState[GitLabGroupId]
                || !(newState[GitLabGroupId] || '').match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [GitLabGroupId]: eventData.get(GitLabGroupId),
            [TeamName]: eventData.get(TeamName),
        });
    }

    return (
        <Box sx={{
            display: 'flex',
            height: '100%'
        }}>
            <Box component='form'
                 onSubmit={submitHandler}
                 sx={{display: 'flex', flexDirection: 'column'}}>
                <TextField required={true}
                           value={state[TeamName]}
                           onInput={(e) => {
                               inputChangeHandler(TeamName, e.target.value);
                           }}
                           name={TeamName}
                           sx={{
                               paddingBottom: '10px',
                               maxWidth: '500px'
                           }}
                           label={Dictionary.teamName}
                           variant="filled" />
                <TextFieldWithRegexValidation value={state[GitLabGroupId]}
                                              onChange={(value) => {
                                                  inputChangeHandler(GitLabGroupId, value);
                                              }}
                                              onError={(value) => {
                                                  inputChangeHandler(GitLabGroupId, value);
                                              }}
                                              name={GitLabGroupId}
                                              label={Dictionary.id}/>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        mt: 3,
                        mb: 2,
                        width: '500px',
                        background: state.disableSubmit ? Colors.disableBackground : '',
                        color: state.disableSubmit ? Colors.grayedOut : '',
                        ':hover': {
                            background: state.disableSubmit ? Colors.disableBackground : '',
                        }
                    }}>
                    {Dictionary.submit}
                </Button>
            </Box>
        </Box>);
}
