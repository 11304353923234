import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';
import { AddSettings } from './addSettings/addSettings';
import { SelectedSetting } from './selectedSetting/selectedSetting';

const CTRL = 'Control';
const ADD = 'q'
let ctrlDown = false;

export const SettingsSelector = ({options = [], selections = [], onChange, keepAddOpen = false,
                                     applyDefaultSettings, tenant}) => {

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardShortcut);
        window.addEventListener('keyup', handleKeyboardKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyboardShortcut);
            window.removeEventListener('keyup', handleKeyboardKeyUp);
        };
    }, []);

    const handleKeyboardKeyUp = (event) => {
        const {key} = event;
        if (key === CTRL) {
            ctrlDown = false;
        }
    }

    const handleKeyboardShortcut = (event) => {
        const {key} = event;
        if(key === CTRL) {
            ctrlDown = true;
            return;
        } else if (ctrlDown && key === ADD && !addItem.add) {
            event.preventDefault();
            event.stopPropagation();
            addItemField();
        }
        ctrlDown = false;
    }

    const [addItem, setAddItem] = useState({
        add: keepAddOpen
    })

    const addItemField = () => {
        setAddItem({...addItem, add: true});
    }

    const handleSubmitAddItem = (item) => {
        onChange([...selections, item]);
        setTimeout(() => {
            document.getElementById('selected-settings-wrapper')
                ?.scrollTo(0, selections.length * 30);
        }, 0);
        closeAddItem();
    }

    const closeAddItem = () => {
        setAddItem({
            add: keepAddOpen
        });
    }

    const deleteSelection = (item) => {
        onChange(selections.filter(selection => selection.id !== item.id));
    }

    const handleEdit = (item) => {
        onChange(selections.map(selection => {
            return selection.id === item.id ? item : selection;
        }));
    }

    return (<Box sx={{height: '100%'}}>

        <Box id='selected-settings-wrapper' sx={{
            height: 'calc(100% - 30px)',
            maxHeight: '400px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            padding: '0 10px',
            boxSizing: 'border-box',
        }}>
            {selections.map((item, index) =>
                <section key={index}>
                    <SelectedSetting setting={item}
                                     onEdit={handleEdit}
                                     onDelete={() => {
                                         deleteSelection(item)
                                     }}/>
                </section>)}
        </Box>

        {addItem.add &&
            <Box sx={{marginTop: '0'}}>
                {selections?.length > 10 &&
                    <Box sx={{
                        width:'100%',
                        height: '1px',
                        boxShadow: '-1px -3px 6px 0',
                        marginTop: '7px'
                    }}/>
                }
                <AddSettings options={options.filter(op => !selections.find(s => s.id === op.id))}
                             onSubmit={handleSubmitAddItem}
                             onCancel={keepAddOpen ? undefined :closeAddItem}/>
            </Box>
        }

        {(!selections || selections.length === 0) && !addItem.add &&
            <Typography variant='h5'
                        sx={{color: Colors.mainHighlight,
                            cursor: 'context-menu',
                            marginLeft: '35px'
                        }}>
                <InfoRounded sx={{ width: '15px'}}/>
                {Dictionary.addSettings}
            </Typography>}

        {(!addItem.add && options.filter(op => !selections.find(s => s.id === op.id)).length > 0) &&
            <Box sx={{
                color: Colors.mainHighlight,
                display: 'flex',
                alignItems: 'flex-start',
                cursor: 'pointer',
                margin: '10px 0 0 35px',
                width: 'fit-content',
                ':hover': {textDecoration: 'underline'}
            }}
                 onClick={addItemField}>
                {Dictionary.addItem} ({Dictionary.ctrlAddItem})
            </Box>
        }
        {applyDefaultSettings &&
            <Box sx={{
                color: Colors.mainHighlight,
                display: 'flex',
                alignItems: 'flex-start',
                cursor: 'pointer',
                margin: '10px 0 0 35px',
                width: 'fit-content',
                ':hover': {textDecoration: 'underline'}
            }}
                 onClick={applyDefaultSettings}>
                {Dictionary.applyDefaultSettings} ({tenant.name})
            </Box>
        }
    </Box>);
}
