import httpService from './http.service';
import { APIList } from '../consts/apiList';

class PipelinesService {
    loadPipelines() {
        return httpService._get(APIList.pipelines);
    }

    createPipeline(ppl) {
        return httpService._post(APIList.createPipeline, ppl);
    }

    deleteSinglePipeline({name, id}) {
        return httpService._delete(APIList.deleteSinglePipeline, {name, id});
    }

    deleteBulkPipeline(pipelines) {
        return httpService._delete(APIList.deleteBulkPipelines, {pipelines});
    }

    updatePipeline(pipeline = {}) {
        return httpService._put(APIList.updatePipeline, {...pipeline});
    }
}

export default new PipelinesService();
