import React, { useState } from 'react';
import {
    Box, Button, FormControl,
    InputLabel, MenuItem, Modal,
    OutlinedInput, Select, TextField,
    Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { EditRounded } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Colors } from '../../../../consts/colors.const';
import { Dictionary } from '../../../../consts/dictionary';
import { BRANCHES } from '../repositoriesEditor';
import { SettingsSelector } from '../../../common/settingsSelector/settingsSelector';
import * as _ from 'lodash';


export const BranchSettings = ({ branches = [], onChange, tenant}) => {

    const allBranchSettingsOptions = useSelector((state) => {
        return state.repositories.settings?.branches;
    });

    const [selectedBranch, setSelectedBranch] = useState(branches?.at(0));

    const [openModal, setOpenModal] = useState({
        open: false
    });

    const handleBranchSettingsChange = (selection) => {
        selectedBranch.settings = selection;
        onChange(BRANCHES, branches);
    }

    const handleCloseModal = () => {
        setOpenModal({open: false});
    }

    const submitNewBranch = (newBranchName) => {
        const newBranch = {
            id: (branches.length + 1) * -1,
            name: newBranchName,
            settings: []
        };
        branches.push(newBranch);
        setSelectedBranch(newBranch);
        handleCloseModal();
        onChange(BRANCHES, branches);
    }

    const handleEditBranchName = (newBranchName, branch) => {
        branch.name = newBranchName;
        handleCloseModal();
        onChange(BRANCHES, branches);
    }

    const applyDefaultSettings = () => {
        if (!selectedBranch) { return; }
        const defaults = _.cloneDeep(tenant?.templates?.branches);
        selectedBranch.settings = _.uniqBy([...defaults, ...selectedBranch.settings], (item) => item.id);
        setSelectedBranch(selectedBranch);
        onChange(BRANCHES, branches);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '50px'
            }}>

            {branches.length > 0 &&
                    <FormControl variant="outlined"
                                 sx={{width: '400px'}}>

                        <InputLabel id="demo-multiple-name-label">{Dictionary.branch}</InputLabel>

                        <Select
                            required={true}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedBranch}
                            input={<OutlinedInput label={Dictionary.branch}/>}
                            onChange={(e) => {
                                setSelectedBranch(e?.target?.value)
                            }}>

                            {branches.map((t) => (
                                <MenuItem
                                    key={t.name}
                                    value={t}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                <Tooltip title={Dictionary.createNewBranch}>
                    <AddCircleOutlineIcon sx={{
                        width: '30px',
                        height: '30px',
                        right: '28px',
                        bottom: '22px',
                        marginLeft: '20px',
                        cursor: 'pointer',
                        ':hover': {color: Colors.mainHighlight}
                    }}
                                          onClick={() => {
                                              setOpenModal({open: true});
                                          }}/>
                </Tooltip>
                {selectedBranch &&
                <Tooltip title={Dictionary.editBranchName}>
                    <EditRounded sx={{
                        ':hover': {color: Colors.mainHighlight},
                        width: '30px',
                        height: '30px',
                        right: '28px',
                        bottom: '22px',
                        marginLeft: '20px',
                        cursor: 'pointer'
                    }}
                                 onClick={() => {
                                     setOpenModal({open: true, item: selectedBranch});
                                 }}/>
                </Tooltip>}
            </Box>


            {selectedBranch &&
                <Box sx={{
                    marginTop: '50px',
                    width: '600px',
                    maxHeight: '300px',
                    marginLeft: '50px'
                }}>
                    <SettingsSelector options={allBranchSettingsOptions}
                                      onChange={handleBranchSettingsChange}
                                      applyDefaultSettings={tenant ? applyDefaultSettings : undefined}
                                      tenant={tenant}
                                      selections={selectedBranch.settings}/>
                </Box>}

            <Modal open={openModal.open}
                   onClose={handleCloseModal}
                   sx={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}>
                <Box sx={{
                    width: '500px',
                    height: '300px',
                    background: Colors.light,
                    position: 'relative',
                    borderRadius: '4px'
                }}>
                    <AddBranchModal submitNewBranch={submitNewBranch}
                                    editItem={openModal.item}
                                    submitEditBranch={handleEditBranchName}
                                    handleCloseModal={handleCloseModal}/>
                </Box>
            </Modal>
        </Box>);
}


const AddBranchModal = ({submitNewBranch, handleCloseModal, editItem, submitEditBranch}) => {

    const [newBranchName, setNewBranchName] = useState(editItem?.name || '');


    const handleSubmit = () => {
        if (editItem) {
            submitEditBranch(newBranchName, editItem);
        } else {
            submitNewBranch(newBranchName);
        }
    }

    return (<>
        <Box sx={{
            borderRadius: '4px',
            width: '100%',
            height: '30px',
            background: Colors.dark,
            color: Colors.grayedOut,
            padding: '10px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase'}}>
            {Dictionary.addBranchOrPattern}
        </Box>

        <FormControl sx={{
            marginTop: '20px',
            width: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>

            <TextField required={true}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               handleSubmit(newBranchName);
                           }
                       }}
                       value={newBranchName}
                       onInput={(e) => {
                           setNewBranchName(e.target.value)
                       }}
                       sx={{
                           width: '80%'
                       }}
                       label={Dictionary.branchName}
                       variant="filled"/>
        </FormControl>

        <Box sx={{
            position: 'absolute',
            bottom: '5px',
            right: '5px'
        }}>
            <Button variant='contained'
                    color='primary'
                    disableElevation
                    disabled={!newBranchName}
                    onClick={handleSubmit}
                    sx={{marginRight: '10px'}}>
                {Dictionary.submit}
            </Button>
            <Button variant="outlined"
                    color="primary"
                    onClick={handleCloseModal}>
                {Dictionary.cancel}
            </Button>
        </Box>
    </>);
}
