import Diversity3Icon from '@mui/icons-material/Diversity3';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import Assignment from '@mui/icons-material/Assignment';
import { HomeWork } from '@mui/icons-material';
import { Dictionary } from './dictionary';
import { RouteTable } from "./routeTable";
import { UserRole } from '../enums/userRole.enum';
import { EPage } from '../enums/pages.enum';


export const PageIcon = {
    [Dictionary.pages.tenants.name]: <HomeWork/>,
    [Dictionary.pages.pipelines.name]: <LinearScaleIcon/>,
    [Dictionary.pages.bricks.name]: <SchemaOutlinedIcon/>,
    [Dictionary.pages.repositories.name]: <Assignment/>,
    [Dictionary.pages.teams.name]: <Diversity3Icon/>,
}

export const Pages = [
    {
        id: EPage.teams,
        name: Dictionary.pages.teams.name,
        route: RouteTable.homeRoutes.teams,
        userRole: UserRole.admin,
        userRoleEdit: UserRole.admin,
    },
    {
        id: EPage.tenants,
        name: Dictionary.pages.tenants.name,
        route: RouteTable.homeRoutes.tenants,
        userRole: UserRole.admin,
        userRoleEdit: UserRole.admin,
    },
    {
        id: EPage.pipelines,
        name: Dictionary.pages.pipelines.name,
        route: RouteTable.homeRoutes.pipelines,
        userRole: UserRole.user,
        userRoleEdit: UserRole.devOps,
    },
    {
        id: EPage.bricks,
        name: Dictionary.pages.bricks.name,
        route: RouteTable.homeRoutes.bricks,
        userRole: UserRole.user,
        userRoleEdit: UserRole.devOps,
    },
    {
        id: EPage.repositories,
        name: Dictionary.pages.repositories.name,
        route: RouteTable.homeRoutes.repositories,
        userRole: UserRole.user,
        userRoleEdit: UserRole.devOps,
    },
];
