import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import * as _ from 'lodash';
import store from './redux/store';
import App from './App';
import './index.css';

Object.defineProperty(Array.prototype, 'last', {
    value: function () {
        return this[this.length - 1];
    }
});

Object.defineProperty(Array.prototype, 'unique', {
    value: function (field) {
        const map = {};
        if (!field) {
            this.forEach(item => {map[item] = item});
        } else {
            this.forEach(item => {
                const value = _.get(item, field);
                map[value] = item}
            )
        }
        return Object.values(map);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

reportWebVitals();
