import httpService from './http.service';
import { APIList } from '../consts/apiList';

class BricksService {
    loadBricks() {
        return httpService._get(APIList.bricks);
    }

    createBrick(brick) {
        return httpService._post(APIList.createBrick, brick);
    }

    deleteSingleBrick(brick) {
        return httpService._delete(APIList.deleteSingleBrick, brick);
    }

    deleteBulkBrick(bricks) {
        return httpService._delete(APIList.deleteBulkBricks, {bricks});
    }

    updateBrick(brick = {}) {
        return httpService._put(APIList.updateBrick, {...brick});
    }
}

export default new BricksService();
