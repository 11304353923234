import React from 'react';
import {Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

export const SHTableRowExpand = ({open, data}) => {

    return (
        <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0, borderBottom: open ? '1px solid lightgray' : 0}} colSpan={6}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            History
                        </Typography>

                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Total price ($)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((dataRow) => (
                                    <TableRow key={dataRow.date}>
                                        <TableCell component="th" scope="row">
                                            {dataRow.date}
                                        </TableCell>
                                        <TableCell>{dataRow.customerId}</TableCell>
                                        <TableCell align="right">{dataRow.amount}</TableCell>
                                        <TableCell align="right">
                                            {Math.round(dataRow.amount) / 100}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
