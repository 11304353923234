import React, { useState } from 'react';
import { Button, Box, Modal, TextField } from '@mui/material';
import * as ICONS from '@mui/icons-material';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import { IconSelector } from './iconSelector/iconSelector';
import { TextFieldWithRegexValidation } from '../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';

export const BrickName = 'name';
export const BrickSHName = 'skyhawksName';
export const BrickIcon = 'brickIcon';

export const BricksEditor = ({onSubmit, brickItem}) => {

    const [state, setState] = useState({
        disableSubmit: !brickItem?.name || !brickItem?.skyhawksName,
        [BrickName]: brickItem?.name || '',
        [BrickSHName]: brickItem?.skyhawksName || '',
        [BrickIcon]: brickItem?.icon,
    });


    const [open, setOpen] = useState(false);

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[BrickName]
                || !newState[BrickSHName]
                || !newState[BrickSHName].match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [BrickName]: eventData.get(BrickName),
            [BrickSHName]: eventData.get(BrickSHName),
            icon: state[BrickIcon],
        });
    }

    const handleClose = () => {
        setOpen(false);
    }

    return(<Box component='form'
               onSubmit={submitHandler}
               sx={{display: 'flex', flexDirection: 'column'}}>
        <TextField required={true}
                   value={state[BrickName]}
                   onInput={(e) => {
                       inputChangeHandler(BrickName, e.target.value);
                   }}
                   name={BrickName}
                   sx={{
                       paddingBottom: '10px',
                       maxWidth: '500px'
                   }}
                   label={Dictionary.brickName}
                   variant="filled" />
        <TextFieldWithRegexValidation value={state[BrickSHName]}
                                      label={Dictionary.brickMachineName}
                                      onChange={(value) => {
                                          inputChangeHandler(BrickSHName, value);
                                      }}
                                      onError={(value) => {
                                          inputChangeHandler(BrickSHName, value);
                                      }}
                                      name={BrickSHName}/>
        <Button variant='outlined' sx={{
            paddingBottom: '10px',
            maxWidth: '500px'
        }} onClick={() => {
            setOpen(true);
        }}>{state[BrickIcon] ? Dictionary.changeIcon : Dictionary.addIcon}
            {state[BrickIcon] &&
                <Box component={ICONS[state[BrickIcon]]} sx={{
                    position: 'absolute',
                    top: '7px',
                    right: '10px'
                }}/>}
        </Button>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
                mt: 3,
                mb: 2,
                width: '500px',
                background: state.disableSubmit ? Colors.disableBackground : '',
                color: state.disableSubmit ? Colors.grayedOut : '',
                ':hover': {
                    background: state.disableSubmit ? Colors.disableBackground : '',
                }
            }}>
            {Dictionary.submit}
        </Button>

        <Modal open={open}
               sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Box>
                <IconSelector onClose={handleClose} onSelect={(icon) => {
                    handleClose();
                    inputChangeHandler(BrickIcon, icon);
                }}/>
            </Box>
        </Modal>
    </Box>)
}
