export const Dictionary  = {
    activate: 'activate',
    addBranchOrPattern: 'add new branch / branch pattern',
    addItem: '+ add item',
    addIcon: 'add icon',
    addSettings: 'Please add settings',
    appName: 'skyhawks',
    appVersion: '0.0.1',
    applyDefaultSettings: '+ apply default settings',
    applyDefaultPipeline: '+ apply $TENANT_NAME$ default pipeline',
    authenticate: 'authenticate',
    authenticating: 'authenticating...',
    branch: 'branch',
    branchName: 'branch name',
    cancel: 'cancel',
    ctrlAddItem: 'CTRL + q',
    createNewBranch: 'Create new branch',
    deactivate: 'deactivate',
    delete: 'delete',
    edit: 'edit',
    editBranchName: 'Edit branch name',
    geHealthcare: 'GE Healthcare',
    gitLabGroupID: 'GitLab group ID',
    id: 'id',
    invalidInputDefault: 'invalid input, use only english letters, numbers or "-"',
    repoSettings: 'repository settings',
    repoBranchesSettings: 'branches settings',
    loading: 'loading',
    loadingRepositories: 'loading repositories',
    logout: 'logout',
    mainMenuPagesTitle: 'pages',
    noMatchItems: 'no items match',
    repositoriesHeader: 'Repositories (optionally)',
    repositoryName: 'Repository name',
    search: 'search',
    selectIcon: 'select icon',
    settings: 'settings',
    signIn: 'sign in',
    sso: 'sso',
    submit: 'submit',
    tenantName: 'Tenant name',
    teamName: 'Team name',
    templates: 'templates',
    pipelineName: 'Pipeline name',
    pipelineNameAndTenant: 'name & tenant',
    pipelineBricks: 'bricks',
    pipeline: 'pipeline',
    repository: 'repository',
    brickName: 'Brick name',
    brickMachineName: 'Brick machine name (Skyhawk name)',
    changeIcon: 'change icon',
    tryAgain: 'try again',
    version: 'version',
    selectBricksHint: 'Drag and Drop Bricks from the options below',
    alert: {
        loginFailure: 'failed to login',
        loadPipelinesFailure: 'failed to load Pipelines',
        pleaseTryAgain: 'Please try again',
        deletePipelineFailure: 'failed to delete Pipeline "$PIPELINE_NAME$"',
    },
    pages: {
        teams: {
            name: 'teams',
            create: 'create new team',
            reload: 'reload teams',
            loadingText: 'Loading Teams',
            loadDataFailure: 'failed to load Teams',
            updateFailure: 'failed to update team',
            createFailure: 'failed to create team "$ITEM_NAME$"',
            deleteFailure: 'failed to delete team "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ teams',
            addItemTooltip: 'add new team',
        },
        tenants: {
            name: 'tenants',
            create: 'create new tenant',
            reload: 'reload tenants',
            loadingText: 'Loading Tenants',
            loadDataFailure: 'failed to load Tenants',
            updateFailure: 'failed to update tenant',
            createFailure: 'failed to create tenant "$ITEM_NAME$"',
            deleteFailure: 'failed to delete tenant "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ tenants',
            addItemTooltip: 'add new tenant',
        },
        pipelines: {
            name: 'pipelines',
            create: 'create new pipeline',
            reload: 'reload pipelines',
            loadingText: 'Loading Pipelines',
            loadDataFailure: 'failed to load pipelines',
            updateFailure: 'failed to update pipeline',
            createFailure: 'failed to create pipeline "$ITEM_NAME$"',
            deleteFailure: 'failed to delete pipeline "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ pipelines',
            addItemTooltip: 'add new pipeline',
        },
        bricks: {
            name: 'bricks',
            create: 'create new brick',
            reload: 'reload bricks',
            loadingText: 'Loading Bricks',
            loadDataFailure: 'failed to load bricks',
            updateFailure: 'failed to update brick',
            createFailure: 'failed to create brick "$ITEM_NAME$"',
            deleteFailure: 'failed to delete brick "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ bricks',
            addItemTooltip: 'add new brick',
        },
        repositories: {
            name: 'repositories',
            create: 'create new repository',
            reload: 'reload repositories',
            loadingText: 'Loading Repositories',
            loadDataFailure: 'failed to load Repositories',
            updateFailure: 'failed to update repository',
            createFailure: 'failed to create repository "$ITEM_NAME$"',
            deleteFailure: 'failed to delete repository "$ITEM_NAME$"',
            deleteBulkFailure: 'failed to delete $NUM_OF_ITEMS$ repositories',
            addItemTooltip: 'add new repository',
        },
    }
}

export const DictionaryWithParam = (str = '', params = {}) => {
    if (!str) {
        return '';
    }
    Object.keys(params).forEach(key => {
        str = str.replace(`$${key}$`, params[key]);
    });
    return str;
}
