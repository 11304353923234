import httpService from './http.service';
import { APIList } from '../consts/apiList';

class TenantsService {
    loadTenants() {
        return httpService._get(APIList.tenants);
    }

    createTenant(tenant) {
        return httpService._post(APIList.createTenant, {...tenant});
    }

    deleteBulkTenant(tenants) {
        return httpService._delete(APIList.deleteBulkTenants, {tenants});
    }

    deleteSingleTenant({name, id}) {
        return httpService._delete(APIList.deleteSingleTenant, {name, id});
    }

    updateTenant(tenant = {}) {
        return httpService._put(APIList.updateTenant, {...tenant});
    }
}

export default new TenantsService();
