import httpService from './http.service';
import { APIList } from '../consts/apiList';

class TeamsService {
    loadTeams() {
        return httpService._get(APIList.teams);
    }

    createTeam(teams) {
        return httpService._post(APIList.createTeam, {...teams});
    }

    deleteBulkTeam(teams) {
        return httpService._delete(APIList.deleteBulkTeams, {tenants: teams});
    }

    deleteSingleTeam({name, id}) {
        return httpService._delete(APIList.deleteSingleTeam, {name, id});
    }

    updateTeam(team = {}) {
        return httpService._put(APIList.updateTeam, {...team});
    }
}

export default new TeamsService();
