export const BaseAPI = 'http://127.0.0.1:3200/';
const TenantsAPI = `${BaseAPI}tenants/`;
const TeamsAPI = `${BaseAPI}teams/`;
const PipelinesAPI = `${BaseAPI}pipelines/`;
const BricksAPI = `${BaseAPI}bricks/`;
const RepositoriesAPI = `${BaseAPI}repositories/`;

const BE_API = 'http://10.231.84.48/v1/';
//const TenantsAPI = `${BE_API}teams`;

export const APIList = {
    login: `${BaseAPI}login`,

    teams: TeamsAPI,
    createTeam: TeamsAPI,
    deleteSingleTeam: `${TeamsAPI}id`,
    deleteBulkTeams: TeamsAPI,
    updateTeam: `${TeamsAPI}id`,

    tenants: TenantsAPI,
    createTenant: TenantsAPI,
    deleteSingleTenant: `${TenantsAPI}id`,
    deleteBulkTenants: TenantsAPI,
    updateTenant: `${TenantsAPI}id`,

    pipelines: PipelinesAPI,
    deleteBulkPipelines: PipelinesAPI,
    deleteSinglePipeline: `${PipelinesAPI}id`,
    createPipeline: PipelinesAPI,
    updatePipeline: `${PipelinesAPI}id`,

    bricks: BricksAPI,
    createBrick: BricksAPI,
    deleteBulkBricks: BricksAPI,
    deleteSingleBrick: `${BricksAPI}id`,
    updateBrick: `${BricksAPI}id`,

    repositories: RepositoriesAPI,
    deleteSingleRepository: `${RepositoriesAPI}id`,
    deleteBulkRepository: RepositoriesAPI,
    loadRepositorySettings: `${RepositoriesAPI}settings`,
    createRepository: RepositoriesAPI,
    updateRepository: `${RepositoriesAPI}id`,
}

export const BaseCognitoAPI = 'https://stage-skyhawks-login.auth.us-east-1.amazoncognito.com/';
const BaseAuthApi = `${BaseCognitoAPI}oauth2/`;
export const AuthLogoutApi = `${BaseCognitoAPI}oauth2/revoke`
export const tokenApi = `${BaseAuthApi}token`;
export const CLIENT_ID = '1a13q50gi7ut15nufat05gm6f0';
const location = window.location;


export const AuthApi = {
    token: tokenApi,
    authorize: `${BaseAuthApi}authorize`,
    authorizeParams: {
        identity_provider: 'GESSO',
        response_type: 'CODE',
        client_id: CLIENT_ID,
        redirect_uri: `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}/auth-callback`,
    }
}
