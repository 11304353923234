import React, {useEffect, useState} from 'react';
import { Box, Card } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dictionary } from '../../../../consts/dictionary';
import { SHTable } from '../../SHTable/SHTable';
import { FiltersBox } from "../../filtersBox/filtersBox";

export const Grid = ({ bulkDelete, deleteSingleItem, editItem, onCreateNewNavButton, tableData, contextDictionary} ) => {

    const [state, setState] = useState({
        checkedItems: [],
        rows: JSON.parse(JSON.stringify(tableData?.list || [])),
        filteredRows: [],
        columns: tableData?.tableColumns || [],
        searchText: '',
        subs: [],
    });
    useEffect(() => {
        const rows = tableData?.list?.filter(r => r?.name?.toLowerCase().indexOf(state.searchText.toLowerCase()) > -1);
        const clonedRows = JSON.parse(JSON.stringify(rows || []));
        setState({
            ...state,
            filteredRows: clonedRows,
            columns: tableData?.tableColumns || [],
            rows: clonedRows,
        });
        return () => {
        };
    }, [tableData]);

    const searchHandler = (searchText = '') => {
        const filteredRows = state.rows.filter(r => r?.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        setState({
            ...state,
            filteredRows,
            searchText
        });
    }

    return (
        <Card sx={{background: 'transparent', boxShadow: 'none', height: '100%'}}>

            <Box sx={{marginBottom: '25px'}}>
                <FiltersBox searchHandler={searchHandler}/>
            </Box>

            {(state.filteredRows) && <SHTable rows={state.filteredRows}
                                              columns={state.columns}
                                              searchText={state.searchText}
                                              addCollapse={false}
                                              addCheckbox={true}
                                              rowActions={[{
                                                  name: Dictionary.edit, callback: editItem
                                              },
                                                  {
                                                      name: Dictionary.delete, callback: deleteSingleItem
                                                  }]}
                                              bulkActions={[{
                                                  name: Dictionary.activate,
                                                  icon: <CheckCircleIcon/>,
                                                  callback: () => {
                                                      alert('TBD activate all');
                                                  }
                                              },
                                                  {
                                                      name: Dictionary.deactivate,
                                                      icon: <DoNotDisturbOnIcon/>,
                                                      callback: () => {
                                                          alert('TBD deactivate all');
                                                      }
                                                  },
                                                  {
                                                      name: Dictionary.delete,
                                                      icon: <DeleteIcon/>,
                                                      callback: () => {
                                                          bulkDelete(state.checkedItems);
                                                      }
                                                  }
                                              ]}
                                              addItemHandler={{
                                                  tooltip: contextDictionary.addItemTooltip,
                                                  callback: onCreateNewNavButton
                                              }}
                                              checkedItems={state.checkedItems}
                                              onSelectionChanged={(checkedItems) => {
                                                  setState({
                                                      ...state,
                                                      checkedItems: checkedItems,
                                                  })
                                              }}
            />}
        </Card>
    )
}
