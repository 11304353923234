import React from 'react';
import { useSelector } from 'react-redux';
import { Select, FormControl, InputLabel,
    MenuItem, OutlinedInput, TextField,
    Typography, Box } from '@mui/material';
import { Dictionary } from '../../../../consts/dictionary';
import { GLOBAL_SETTINGS, NAME, TENANT } from '../repositoriesEditor'
import { Colors } from '../../../../consts/colors.const';
import { Spinner } from '../../../common/spinner/spinner';
import { SettingsSelector } from '../../../common/settingsSelector/settingsSelector';

export const RepositorySettings = ({id, name, tenant, globalSettings = [], onChange}) => {

    const allGlobalSettings = useSelector((state) => {
        return state.repositories.settings?.global;
    });

    const tenants = useSelector((state) => {
        return state.tenants;
    });

    const handleSettingsChange = (selection) => {
        onChange(GLOBAL_SETTINGS, selection);
    };

    const handleTenantSelection = (selectedTenant) => {
        onChange(TENANT, selectedTenant);
    }

    return(<>

        {(!allGlobalSettings ||
          !tenants ||
          tenants?.tableColumns?.length === 0) && <Spinner label={Dictionary.loading}/>}

        {id &&
            <Typography variant='h5'
                       sx={{
                           marginTop: '50px',
                           width: '500px',
                           textAlign: 'center',
                           color: Colors.mainHighlight
                       }}>
                ID: {id}
            </Typography>
        }

        {(tenants && tenants.tableColumns.length > 0) &&
            <FormControl variant="outlined"
                         sx={{
                             width: '500px',
                             marginTop: '50px'
                         }}>
                <InputLabel id="demo-multiple-name-label">{Dictionary.tenantName}</InputLabel>
                <Select
                    required={true}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={tenants.list.find(t => t.id === tenant?.id) || ''}
                    input={<OutlinedInput label={Dictionary.tenantName}/>}
                    onChange={(e) => {
                        handleTenantSelection(e?.target?.value);
                    }}
                >
                    {tenants.list.map((t) => (
                        <MenuItem
                            key={t.name}
                            value={t}>
                            {t.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        }

        <FormControl sx={{
            marginTop: '20px',
            width: '500px',
        }}>

            <TextField required={true}
                       value={name}
                       onInput={(e) => {
                           onChange(NAME, e.target.value);
                       }}
                       name={NAME}
                       sx={{
                           width: '500px'
                       }}
                       label={Dictionary.repositoryName}
                       variant="filled"/>
        </FormControl>
        <Box sx={{
            marginTop: '50px',
            width: '600px',
            maxHeight: '300px',
        }}>
            <SettingsSelector options={allGlobalSettings}
                              selections={globalSettings}
                              onChange={handleSettingsChange}/>
        </Box>
    </>);
}
