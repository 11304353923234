import React, {useState} from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setRepositoriesSettings } from '../../../../redux/sclices/repositories.slice';
import * as _ from 'lodash';
import { Colors } from '../../../../consts/colors.const';
import { Dictionary } from '../../../../consts/dictionary';
import { Template } from '../../../../enums/template';
import { TemplatesOptions } from '../../../../consts/templatesOptions.const';
import repositoriesService from '../../../../services/repositories.service';
import { RepositoryTemplate } from './repositoryTemplate/repositoryTemplate';
import { Spinner } from '../../../common/spinner/spinner';

export const TenantTemplates = ({onChange, selections}) => {

    const dispatch = useDispatch();

    const allSettings = useSelector((state) => {
        return state.repositories.settings;
    });

    const [didInit, setDidInit] = useState(false);

    const [state, setState] = useState({
        options: _.cloneDeep(TemplatesOptions),
        selectedOption: Template.Repository,
    });

    (() => {
        if (didInit || allSettings) { return; }
        setDidInit(true);
        repositoriesService.loadSettings().subscribe((data) => {
            dispatch(setRepositoriesSettings(data));
        });
    })();

    return (
        <Box sx={{
            display: 'flex',
            position: 'relative',
            width: '100%'
        }}>

            {!allSettings && <Spinner label={Dictionary.loading}/>}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                opacity: allSettings ? '1' : '0.5'
            }}>
                <Typography variant='h6'
                            sx={{
                                color: Colors.dark,
                                textTransform: 'capitalize',
                                cursor: 'default',
                                marginBottom: '20px'
                            }}>
                    {Dictionary.templates}
                </Typography>

                {state.options.map((option, index) =>
                    <Typography key={index}
                                variant='overline' sx={{
                        color: option.id === state.selectedOption ? Colors.mainHighlight : Colors.grayedOut,
                        cursor: option.id === state.selectedOption ? 'default' : 'pointer',
                        fontWeight: option.id === state.selectedOption ? 'bold' : '',
                        lineHeight: '2.5',
                        marginLeft: '20px',
                        opacity: option.id === state.selectedOption ? '1' : '0.8',
                        ':hover': {
                            opacity: '1',
                        }
                    }} onClick={() => {
                        setState({...state, selectedOption: option.id});
                    }}>
                        {option.name}
                    </Typography>)}

            </Box>
            {allSettings &&
                <Box sx={{
                    width: '100%',
                    marginTop: '50px'
                }}>

                    {state.selectedOption === Template.Repository &&
                        <RepositoryTemplate settings={allSettings.global}
                                            selections={selections?.repositories}
                                            onChange={(data) => {
                                                onChange({...selections, repositories: data});
                                            }}/>}

                    {state.selectedOption === Template.Branch &&
                        <RepositoryTemplate settings={allSettings.branches}
                                            selections={selections?.branches}
                                            onChange={(data) => {
                                                onChange({...selections, branches: data});
                                            }}/>}
                </Box>
            }
        </Box>
    );
}
