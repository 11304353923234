import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Dictionary } from '../../../consts/dictionary';
import { STANDARD_ID_INPUT } from '../../../consts/regex';

export const TextFieldWithRegexValidation = ({value = '', onChange, name, label, onError,
                                                 errorText = Dictionary.invalidInputDefault,
                                                 regex = STANDARD_ID_INPUT}) => {

    const [valid, setValid] = useState(`${value}`.match(regex))

    return <>
        <TextField required={true}
                   error={!valid}
                   value={value}
                   helperText={valid ? '' : errorText}
                   onInput={(e) => {
                       const value = e.target.value;
                       const isValid = value.match(regex);
                       setValid(isValid);
                       if (isValid) {
                           onChange(value);
                       } else {
                           onError(value);
                       }
                   }}
                   sx={{
                       width: '100%',
                       paddingBottom: '10px',
                       maxWidth: '500px'
                   }}
                   name={name}
                   label={label}
                   variant="filled" />
    </>
}
