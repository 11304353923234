import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import { TenantMeta } from './tenantMeta/tenantMeta';
import { TenantTemplates } from './tenantTemplates/tenantTemplates';

export const TenantName = 'name';
export const GitLabGroupId = 'id';
export const TEAM = 'team';
export const Templates = 'templates';

export const TenantsEditor = ({ onSubmit, tenant }) => {

    const [state, setState] = useState({
        disableSubmit: !tenant?.name || !tenant?.id,
        [TenantName]: tenant?.name,
        [GitLabGroupId]: tenant?.id,
        [TEAM]: tenant?.team,
        [Templates]: tenant?.templates,
    });

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit: !newState[TenantName]
                || !newState[TEAM]
                || !newState[GitLabGroupId]
                || !newState[GitLabGroupId].match(STANDARD_ID_INPUT)
        })
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const eventData = new FormData(event?.currentTarget);
        onSubmit({
            [GitLabGroupId]: eventData.get(GitLabGroupId),
            [TenantName]: eventData.get(TenantName),
            [Templates]: state[Templates],
            [TEAM]: state[TEAM],
        });
    }

    return (
        <Box sx={{
            display: 'flex',
            height: '100%'
        }}>
            <TenantMeta submitHandler={submitHandler}
                        inputChangeHandler={inputChangeHandler}
                        disableSubmit={state.disableSubmit}
                        gitLabGroupId={state[GitLabGroupId]}
                        tenantName={state[TenantName]}
                        selectedTeam={state[TEAM]}/>

            <Divider orientation="vertical"
                     flexItem
                     sx={{margin: '0 30px'}}/>

            <TenantTemplates selections={state[Templates]}
                             tenant={tenant}
                             onChange={(data) => {
                                 inputChangeHandler(Templates, data)
                             }}/>
        </Box>);
}
