import httpService from './http.service';
import { APIList } from '../consts/apiList';

class RepositoriesService {
    loadRepositories() {
        return httpService._get(APIList.repositories);
    }

    createRepository(repository) {
        return httpService._post(APIList.createRepository, {repository});
    }

    deleteBulkRepositories(repositories) {
        return httpService._delete(APIList.deleteBulkRepository, {repositories});
    }

    deleteSingleRepository({name, id}) {
        return httpService._delete(APIList.deleteSingleRepository, {name, id});
    }

    updateRepository(repository = {}) {
        return httpService._put(APIList.updateRepository, {...repository});
    }

    loadSettings() {
        return httpService._get(APIList.loadRepositorySettings)
    }
}

export default new RepositoriesService();
