import React, {useState} from 'react';
import {
    Checkbox,
    IconButton,
    TableCell,
    TableRow,
    Box
} from '@mui/material';
import * as ICONS from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Moment from "react-moment";
import { CHECKBOX, COLLAPSE, ROW_ACTIONS } from '../SHTable';
import { SHTableRowExpand } from './SHTableRowExpand/SHTableRowExpand';
import { SHTableRowActions } from './SHTableRowActions/SHTableRowActions';
import { SHTableCol } from '../../../../enums/shtableCol.enum';
import './SHTableRow.css';

const history = [{
    date: '2020-01-05',
    customerId: '11091700',
    amount: 3,
    price: 7.5
},
    {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
        price: 2.5
    }]


export const SHTableRow = ({row, rowActions, columns, _key, onCheckBoxClick, hasCollapse}) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow key={row.name}>
                {columns.map((col, index) => {
                    switch (col.id) {
                        case ROW_ACTIONS:
                            return <TableCell key={`${_key}_${index}`} width='30px'>
                                <SHTableRowActions row={row} actions={rowActions}/>
                            </TableCell>
                        case COLLAPSE:
                            return (
                                <TableCell key={`${_key}_${index}`} width='30px'>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </TableCell>);
                        case CHECKBOX:
                            return(
                                <TableCell key={`${_key}_${index}`} width='30px'>
                                    <Checkbox checked={row.check} onChange={onCheckBoxClick} className={row.check ? 'checked-table-row' : ''}/>
                                </TableCell>);
                        default:
                            return (
                                <TableCell key={`${_key}_${index}`}>
                                    {col.colType === SHTableCol.text && <>{row[col.id]}</>}
                                    {col.colType === SHTableCol.date && <Moment date={row[col.id]}
                                                                             format='DD/MM/YYYY HH:mm'
                                                                             utc={true}/>}
                                    {col.colType === SHTableCol.object && <>{row[col.id]?.name}</>}
                                    {col.colType === SHTableCol.icon && <Box component={ICONS[row[col.id]]}/>}
                                </TableCell>);
                    }
                })}
            </TableRow>
            {hasCollapse && <SHTableRowExpand open={open} data={history}/>}
        </>
    );
}


