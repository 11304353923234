import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { RouteTable } from '../../../consts/routeTable';
import { SideMenu } from './sideMenu/sideMenu';
import { Header } from './header/header';
import { Tenants } from '../../tenants/tenants';
import { Pipelines } from '../../pipelines/pipelines';
import { Bricks } from '../../bricks/bricks';
import { Repositories } from '../../repositories/repositories';
import { Teams } from '../../teams/teams';
import * as style from './style';

export const Home = () => {
    const navigate = useNavigate();

    const selectedPageComponent = useSelector((state) => {
        return state.pages.currentPage;
    });
    const loginData = useSelector((state) => {
        return state.login.loginData;
    });

    useEffect(() => {
        if (!loginData) {
            navigate(RouteTable.login);
            return;
        }
        if (selectedPageComponent) {
            navigate(`${selectedPageComponent.route}`)
        }
        return () => {};
    }, [selectedPageComponent]);

    return (
        <section style={style.homePage}>
            <section style={style.homePageHeader}>
                <Header/>
            </section>
            <section style={style.homePageBody}>
                <section style={style.homePageMenu}>
                    <SideMenu/>
                </section>
                <section style={style.homePageContent}>

                        <Routes>
                            <Route path={RouteTable.homeRoutes.tenants} element={<Tenants/>}/>
                            <Route path={RouteTable.homeRoutes.teams} element={<Teams/>}/>
                            <Route path={RouteTable.homeRoutes.pipelines} element={<Pipelines/>}/>
                            <Route path={RouteTable.homeRoutes.bricks} element={<Bricks/>}/>
                            <Route path={RouteTable.homeRoutes.repositories} element={<Repositories/>}/>
                        </Routes>

                </section>
            </section>
        </section>
    );
}
