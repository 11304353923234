import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
    Tab,
    Tabs
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import { LoadingStatus } from '../../../enums/loadingStatus.enum';
import { Colors } from '../../../consts/colors.const';
import { APIList } from '../../../consts/apiList';
import { Dictionary } from '../../../consts/dictionary';
import { STANDARD_ID_INPUT } from '../../../consts/regex';
import HttpService from '../../../services/http.service';
import { PipelineMeta } from './pipelineMeta/pipelineMeta';
import { PipelineBricksSelector } from './pipelineBricksSelector/pipelineBricksSelector';

export const NAME = 'name';
export const TENANT = 'tenant';
export const BRICKS = 'bricks';
export const PIPELINE_ID = 'id';

export const PipelineEditor = ({pipeline, onSubmit}) => {

    const TAB = {
        nameAndTenant: 0,
        bricks: 1,
        repository: 2,
    }

    const [selectedTab, setValue] = React.useState(TAB.nameAndTenant);
    const [repos, setRepos] = React.useState({
        data: undefined,
        loadingStatus: LoadingStatus.UnKnown,
    });

    const [state, setState] = useState({
        [NAME]: pipeline?.name || '',
        [TENANT]: pipeline?.tenant,
        [BRICKS]: pipeline?.bricks || [],
        [PIPELINE_ID]: pipeline?.id || '',
        disableSubmit:
            !pipeline?.name ||
            !pipeline?.tenant ||
            !pipeline?.bricks ||
            pipeline?.bricks?.length === 0
    })

    useEffect(() => {
        const _repos = {...repos, loadingStatus: LoadingStatus.InProgress};
        setRepos({...repos, ..._repos});
        HttpService._get(APIList.repositories).subscribe(
            (data)=> {
                setRepos({..._repos, loadingStatus: LoadingStatus.Succeed, data})
            },
            () => {
                setRepos({data: undefined, loadingStatus: LoadingStatus.Failed});
            })
    }, []);

    const submitHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        onSubmit({
            [NAME]: state[NAME],
            [TENANT]: state[TENANT],
            [BRICKS]: state[BRICKS],
            [PIPELINE_ID]: state[PIPELINE_ID],
            id: pipeline?.id,
        });

    }

    const inputChangeHandler = (inputFieldName, value) => {
        const newState = {
            ...state,
            [inputFieldName]: value,
        }
        setState({
            ...newState,
            disableSubmit:
                !newState[NAME] ||
                !newState[TENANT] ||
                !newState[BRICKS] ||
                !newState[PIPELINE_ID] ||
                !newState[PIPELINE_ID].match(STANDARD_ID_INPUT) ||
                newState[BRICKS].length === 0
        })
    }

    const tabItemSX = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    };

    const tabItemIconSX = {
        marginRight: '5px',
        width: '15px',
        height: '15px',
    };

    const tabContentSX = {
        display: 'flex',
            flexDirection: 'row',
        width: '100%',
        height: '100%'
    };

    return (
        <Box component='form'
             onSubmit={submitHandler}
             sx={{height: '100%'}}
             >
            <Box sx={tabContentSX}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedTab}
                    sx={{
                        width: '200px'
                    }}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                    }}>
                    <Tab label={Dictionary.pipelineNameAndTenant}
                         sx={{...tabItemSX}}
                         icon={
                             (state[TENANT] && state[NAME]) ?
                                 <CheckCircleIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.nameAndTenant ? '1' : '0.3',
                                     color: Colors.acceptedColor}}/> :
                                 <DoNotDisturbOnTotalSilenceIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.nameAndTenant ? '1' : '0.3',
                                     color: Colors.alertColor}}/>
                         }
                         {...a11yProps(TAB.nameAndTenant)} />

                    <Tab label={Dictionary.pipelineBricks}
                         sx={{...tabItemSX}}
                         icon={
                             (state[BRICKS] && state[BRICKS].length > 0) ?
                                 <CheckCircleIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.bricks ? '1' : '0.3',
                                     color: Colors.acceptedColor}}/> :
                                 <DoNotDisturbOnTotalSilenceIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.bricks ? '1' : '0.3',
                                     color: Colors.alertColor}}/>
                         }
                         {...a11yProps(TAB.bricks)} />

                    <Tab label={Dictionary.submit}
                         type='submit'
                         disabled={state.disableSubmit}
                         sx={{
                             background: state.disableSubmit ? Colors.disableBackground : Colors.mainHighlight,
                             color: state.disableSubmit ? Colors.grayedOut : `${Colors.light} !important`,
                             width: '150px',
                             boxShadow: !state.disableSubmit &&
                                 '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
                                 '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
                                 '0px 1px 5px 0px rgb(0 0 0 / 12%)',
                             borderRadius: '7px',
                         }}/>


                </Tabs>
                {selectedTab === TAB.nameAndTenant &&
                    <Box value={selectedTab} index={TAB.nameAndTenant} sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <PipelineMeta id={state[PIPELINE_ID]}
                                      name={state[NAME]}
                                      tenant={state[TENANT]}
                                      onChange={inputChangeHandler}/>
                    </Box>}

                {selectedTab === TAB.bricks &&
                    <Box value={selectedTab}
                         index={TAB.bricks}
                         sx={{
                             ...tabContentSX,
                             display: 'flex',
                             flexDirection: 'column'
                         }}>
                        <PipelineBricksSelector bricks={state[BRICKS]}
                                                onChange={(bricks) => {
                                                    inputChangeHandler(BRICKS, bricks);
                                                }}/>
                    </Box>}

            </Box>
        </Box>


    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



