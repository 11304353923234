import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, FormControl, InputLabel, MenuItem, OutlinedInput, TextField, Box } from '@mui/material';
import { Dictionary } from '../../../../consts/dictionary';
import {NAME, PIPELINE_ID, TENANT} from '../pipelineEditor';
import tenantsService from '../../../../services/tenants.service';
import { Spinner } from '../../../common/spinner/spinner';
import { setTenantsData } from "../../../../redux/sclices/tenantsSlice";
import { TextFieldWithRegexValidation } from '../../../common/textFieldWithRegexValidation/textFieldWithRegexValidation';

export const PipelineMeta = ({tenant, name, id = '', onChange}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const dispatch = useDispatch();

    const pipelinesTenants = useSelector((state) => {
        return state.tenants?.list
    });

    useEffect(() => {
        if (!pipelinesTenants || pipelinesTenants.length === 0) {
            setShowSpinner(true);
            tenantsService.loadTenants().subscribe(data => {
                dispatch(setTenantsData(data))
                setShowSpinner(false);
            }, () => {
                setShowSpinner(false);
            });
        }
    }, [])

    return(<>
        {showSpinner && <Spinner label={Dictionary.loading}/>}
        <TextField required={true}
                   value={name}
                   onInput={(e) => {
                       onChange(NAME, e.target.value);
                   }}
                   name={NAME}
                   sx={{
                       marginTop: '50px',
                       width: '500px'
                   }}
                   label={Dictionary.pipelineName}
                   variant="filled"/>
        <Box sx={{
            marginTop: '50px',
            width: '500px'
        }}>
            <TextFieldWithRegexValidation value={id}
                                          label={Dictionary.id}
                                          name={PIPELINE_ID}
                                          onChange={(value) => {
                                              onChange(PIPELINE_ID, value);
                                          }}
                                          onError={(value) => {
                                              onChange(PIPELINE_ID, value);
                                          }}/>

        </Box>

        <FormControl sx={{
            marginTop: '50px',
            width: '500px',
        }}>

            <InputLabel id="demo-multiple-name-label">{Dictionary.tenantName}</InputLabel>
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={tenant?.name || ''}
                onChange={(e) => {
                    const value =  e.target.value;
                    const tenantInstance = pipelinesTenants.find(t => t.name === value);
                    onChange(TENANT, tenantInstance);
                }}
                input={<OutlinedInput label={Dictionary.tenantName}/>}

            >
                {pipelinesTenants.map((tenantOption) => (
                    <MenuItem
                        key={tenantOption.name}
                        value={tenantOption.name}
                    >
                        {tenantOption.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </>);
}
