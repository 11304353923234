import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import { Colors } from '../../../consts/colors.const';
import { Dictionary } from '../../../consts/dictionary';
import { RepositorySettings } from './repositorySettings/repositorySettings';
import { useDispatch, useSelector } from 'react-redux';
import repositoriesService from '../../../services/repositories.service';
import tenantsService from '../../../services/tenants.service';
import { setRepositoriesSettings } from '../../../redux/sclices/repositories.slice';
import { setTenantsData } from '../../../redux/sclices/tenantsSlice';
import { BranchSettings } from './branchSettings/branchSettings';
import { RepositoryPipelineSelector } from './repositoryPipelineSelector/repositoryPipelineSelector';
import * as _ from 'lodash';


export const NAME = 'name';
export const GLOBAL_SETTINGS = 'global';
export const BRANCHES = 'branches';
export const TENANT = 'tenant';
export const PIPELINE = 'pipeline';

const TAB = {
    repoSettings: 0,
    repoBranches: 1,
    repoPipeline: 2,
}

const DEFAULT_BRANCHES = [
    {
        id: -1,
        name: 'main',
        settings: []
    }
];

export const RepositoriesEditor = ({repository, onSubmit}) => {

    const dispatch = useDispatch();

    const [selectedTab, setValue] = useState(TAB.repoSettings);

    const [subs, setSubs] = useState([]);

    const [didInit, setDidInit] = useState(false);

    const [state, setState] = useState({
        [NAME]: repository?.name || '',
        [GLOBAL_SETTINGS]: repository?.global,
        [BRANCHES]: repository?.branches || _.cloneDeep(DEFAULT_BRANCHES),
        [TENANT]: repository?.tenant,
        [PIPELINE]: repository?.pipeline,
        disableSubmit:
            !repository?.name ||
            !repository.global ||
            repository?.global?.length === 0 ||
            !repository?.tenant ||
            !repository?.pipeline,
    });

    const allSettings = useSelector((state) => {
        return state.repositories.settings;
    });

    const tenants = useSelector((state) => {
        return state.tenants;
    });


    (() => {
        if (didInit) { return; }
        setDidInit(true);
        const addedSubs = [];
        if (!tenants || tenants?.tableColumns?.length === 0) {
            addedSubs.push(tenantsService.loadTenants().subscribe((data) => {
                dispatch(setTenantsData(data));
            }));
        }

        if (!allSettings) {
            addedSubs.push(repositoriesService.loadSettings().subscribe((data) => {
                dispatch(setRepositoriesSettings(data));
            }));
        }
        if (addedSubs.length > 0) {
            setSubs([...subs, ...addedSubs]);
        }
    })();

    useEffect(() => {
        return () => {
            subs.forEach(s => { s.unsubscribe(); });
        };
    }, [subs]);


    const submitHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        onSubmit({
            [NAME]: state[NAME],
            [GLOBAL_SETTINGS]: state[GLOBAL_SETTINGS],
            [TENANT]: state[TENANT],
            [BRANCHES]: state[BRANCHES],
            [PIPELINE]: state[PIPELINE],
            id: repository?.id,
        });

    }

    const inputChangeHandler = (inputFieldName, value) => {
        const isInCreateMode = !repository;

        const newState = {
            ...state,
            [inputFieldName]: value,
        }

        if (inputFieldName === TENANT && isInCreateMode) {
            newState[GLOBAL_SETTINGS] = value.templates.repositories;
        }

        setState({
            ...newState,
            disableSubmit:
                !newState[NAME] ||
                !newState[GLOBAL_SETTINGS] ||
                newState[GLOBAL_SETTINGS]?.length === 0 ||
                !newState[TENANT] ||
                !newState[BRANCHES] ||
                newState[BRANCHES]?.length === 0 ||
                !newState[PIPELINE] ||
                newState[PIPELINE]?.length === 0
        });
    }

    const tabItemSX = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    };

    const tabItemIconSX = {
        marginRight: '5px',
        width: '15px',
        height: '15px',
    };

    const tabContentSX = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%'
    };

    return (
        <Box component='form'
             onSubmit={submitHandler}
             sx={{height: '100%'}}>

            <Box sx={tabContentSX}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedTab}
                    sx={{
                        width: '200px'
                    }}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                    }}>
                    <Tab label={Dictionary.repoSettings}
                         sx={{...tabItemSX}}
                         icon={
                             (state[NAME] && state[GLOBAL_SETTINGS]?.length >= 1 && state[TENANT]) ?
                                 <CheckCircleIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.repoSettings ? '1' : '0.3',
                                     color: Colors.acceptedColor}}/> :
                                 <DoNotDisturbOnTotalSilenceIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.repoSettings ? '1' : '0.3',
                                     color: Colors.alertColor}}/>
                         }
                         {...a11yProps(TAB.repoSettings)} />

                    <Tab label={Dictionary.repoBranchesSettings}
                         sx={{...tabItemSX}}
                         icon={<CheckCircleIcon sx={{
                                     ...tabItemIconSX,
                                     opacity:  selectedTab === TAB.repoBranches ? '1' : '0.3',
                                     color: Colors.acceptedColor}}/>}
                         {...a11yProps(TAB.repoBranches)} />

                    <Tab label={Dictionary.pipeline}
                         sx={{...tabItemSX}}
                         icon={(state[PIPELINE]) ?
                             <CheckCircleIcon sx={{
                                 ...tabItemIconSX,
                                 opacity:  selectedTab === TAB.repoPipeline ? '1' : '0.3',
                                 color: Colors.acceptedColor}}/> :
                             <DoNotDisturbOnTotalSilenceIcon sx={{
                                 ...tabItemIconSX,
                                 opacity:  selectedTab === TAB.repoPipeline ? '1' : '0.3',
                                 color: Colors.alertColor}}/>}
                         {...a11yProps(TAB.repoPipeline)} />

                    <Tab label={Dictionary.submit}
                         type='submit'
                         disabled={state.disableSubmit}
                         sx={{
                             background: state.disableSubmit ? Colors.disableBackground : Colors.mainHighlight,
                             color: state.disableSubmit ? Colors.grayedOut : `${Colors.light} !important`,
                             width: '150px',
                             boxShadow: !state.disableSubmit &&
                                 '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
                                 '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
                                 '0px 1px 5px 0px rgb(0 0 0 / 12%)',
                             borderRadius: '7px',
                         }}/>

                </Tabs>
                {selectedTab === TAB.repoSettings &&
                    <Box value={selectedTab}
                         index={TAB.repoSettings}
                         sx={{
                             width: '100%',
                             display: 'flex',
                             flexDirection: 'column',
                             alignItems: 'center',
                         }}>
                        <RepositorySettings id={repository?.id}
                                            globalSettings={state[GLOBAL_SETTINGS]}
                                            name={state[NAME]}
                                            tenant={state[TENANT]}
                                            onChange={inputChangeHandler}/>
                    </Box>}

                {selectedTab === TAB.repoBranches &&
                    <Box value={selectedTab} index={TAB.repoBranches} sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <BranchSettings branches={state[BRANCHES]}
                                        tenant={state[TENANT]}
                                        onChange={inputChangeHandler} />
                    </Box>}

                {selectedTab === TAB.repoPipeline &&
                    <Box value={selectedTab} index={TAB.repoPipeline} sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <RepositoryPipelineSelector pipeline={state[PIPELINE]} onChange={(data) => {
                            inputChangeHandler(PIPELINE, data);
                        }}/>
                    </Box>}
            </Box>
        </Box>


    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



