import React from 'react';
import { SettingsSelector } from '../../../../common/settingsSelector/settingsSelector';

export const RepositoryTemplate = ({settings, selections, onChange}) =>{

    return (
        <>
            <SettingsSelector options={settings}
                              keepAddOpen={true}
                              selections={selections}
                              onChange={onChange}/>
        </>
    )
}
