import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPipelinesData } from '../../../../redux/sclices/pipelines.slice';
import { Dictionary } from '../../../../consts/dictionary';
import pipelinesService from '../../../../services/pipelines.service';
import { Spinner } from '../../../common/spinner/spinner';
import { PipelineBricks } from '../../../pipelines/pipelineEditor/pipelineBricksSelector/pipelineBricks/pipelineBricks';

export const RepositoryPipelineSelector = ({pipeline, onChange}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const dispatch = useDispatch();

    const pipelinesData = useSelector((state) => {
        return state.pipelines?.list;
    });

    useEffect(() => {
        if (!pipelinesData || pipelinesData.length === 0) {
            setShowSpinner(true);
            pipelinesService.loadPipelines().subscribe((data) => {
                setShowSpinner(false);
                dispatch(setPipelinesData(data));
            },
                () => {
                    setShowSpinner(false);

                });
        }
    }, [])

    return (<>
        {showSpinner && <Spinner label={Dictionary.loading}/> }
        <FormControl variant="outlined"
                     sx={{
                         width: '500px',
                         marginTop: '50px'
                     }}>
            <InputLabel id="demo-multiple-name-label">{Dictionary.pipelineName}</InputLabel>
            <Select
                required={true}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={pipelinesData.find(p => p.id === pipeline?.id) || ''}
                input={<OutlinedInput label={Dictionary.pipelineName}/>}
                onChange={(e) => {
                    onChange(e?.target?.value);
                }}
            >
                {pipelinesData?.map((p, index) => (
                    <MenuItem
                        key={`${p.name}_${index}`}
                        value={p}>
                        {p.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        {pipeline &&
            <Box sx={{
                maxHeight: '400px',
                width: '80%',
                marginTop: '50px'
            }}>
                <PipelineBricks bricks={pipeline.bricks}/>
            </Box>
        }
    </>);
}
