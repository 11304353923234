export const UserRole = {
    admin: 10,
    devOps: 20,
    user: 30,
    unAuthorized: 1000,
}

export const UserRoleGroupId = {
    dev: 'g02892076',
    devOps: 'g02892075',
    user: 'g02892073',
}
