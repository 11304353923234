import { Template } from '../enums/template';
import { Dictionary } from './dictionary';

export const TemplatesOptions = [
    {
        id: Template.Repository,
        name: Dictionary.repository
    },
    {
        id: Template.Branch,
        name: Dictionary.branch
    },
];
